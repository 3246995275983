import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import Collection from '../../../components/collection'

const metaTags = {
  description:
    'Browse a list of office and caregiver resources for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Office Support | SUPPRELIN® LA (histrelin acetate)',
}

const hcpResources = [
  {
    thumb: 'SP-05175_Surgeon_MVA_thumbnail.png',
    title: 'Surgical Procedure Overview',
    copy: `Download full instructions for implanting and removing the <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span> implant`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/ab481af8-8284-4b4e-bd3d-5d08d74d0277/ab481af8-8284-4b4e-bd3d-5d08d74d0277_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Instructions Implanting Removing SUPPRELIN LA',
  },
  {
    thumb: 'MM-05382_SRE_form_thumbnail.png',
    title: 'Service Request Enrollment (SRE) Form',
    copy: 'Investigate patient’s coverage to begin the reimbursement process',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/c3bb1661-4660-4a3b-b6f6-34f3ebd0eb31/c3bb1661-4660-4a3b-b6f6-34f3ebd0eb31_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Enrollment Form',
  },
  {
    thumb: 'MM-05413_SRE_instructions_thumbnail.png',
    title: 'SRE Instruction Guide',
    copy: 'Best practices to help complete the SRE Form',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/9fb17585-04dc-409a-a4ef-a72edfa9744a/9fb17585-04dc-409a-a4ef-a72edfa9744a_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Enrollment Form Instructions',
  },
  {
    thumb: 'MM-05411_BI_instructions_SP_thumbnail.png',
    title: 'Quick Reference Guide for Specialty Pharmacy Acquisition',
    copy: 'How to read the BI Results Form for Specialty Pharmacy',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/eb93cc3f-9c0f-44e4-b687-322f40f99e3c/eb93cc3f-9c0f-44e4-b687-322f40f99e3c_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Specialty Pharmacy Results Instruction Guide',
  },
  {
    thumb: 'MM-05412_BI_instructions_Hosp_thumbnail.png',
    title: 'Quick Reference Guide for Hospital Acquisition',
    copy: 'How to read the BI Results Form for Hospital',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/c447ef85-065c-4166-837d-3e139560b1cd/c447ef85-065c-4166-837d-3e139560b1cd_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Hospital Results Instruction Guide',
  },
  {
    thumb: 'MM-05305_LOMN_thumbnail.png',
    title: 'Sample Letter of Medical Necessity',
    copy: 'Claims support for reimbursement of SUPPRELIN<sup>&reg;</sup> LA',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/136ade71-27bb-4edd-ac3b-bff1650c5378/136ade71-27bb-4edd-ac3b-bff1650c5378_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Letter Medical Necessity',
  },
  {
    thumb: 'MM-05303_Appeal_Letter_thumbnail.png',
    title: 'Sample Appeal Tool',
    copy: 'SUPPRELIN<sup>&reg;</sup> LA Implant Claim Appeal Letter',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/e5c67584-a5f7-4aea-87ec-7ecfbd884415/e5c67584-a5f7-4aea-87ec-7ecfbd884415_source__v.pdf',
    pdf: false,
    external: false,
    alt: 'SUPPRELIN LA Appeal Tool',
  },
  {
    thumb: 'implant-removal-tip-sheet-thumbnaill.png',
    title: 'Implant Removal Tip Sheet',
    copy: 'Download this helpful tip sheet to help support SUPPRELIN<sup>&reg;</sup> LA implant removal',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/2f9f913f-f948-4d77-938e-6d685f44a8b7/2f9f913f-f948-4d77-938e-6d685f44a8b7_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Implant removal tip sheet',
  },
  {
    thumb: 'benefits-investigation-results-form-quick-reference-guide-thumbnail.png',
    title: 'Benefits Investigation (BI) Results Form Quick Reference Guide',
    copy: 'How to read the BI Results Form <br class="hide-in-mobile">at a glance',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/86fa0978-2c50-416f-82ae-f088b6b47e29/86fa0978-2c50-416f-82ae-f088b6b47e29_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'BI Results Form Quick Reference Guide',
  },
  {
    thumb: 'sample-benefits-investigation-results-form-thumbnail.png',
    title: 'Sample Benefits Investigation (BI) Results Form',
    copy: 'Sample BI Results Form for illustrative purposes only',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/a89d16d4-7787-49f5-be75-8bcf6621ac0c/a89d16d4-7787-49f5-be75-8bcf6621ac0c_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'BI results form',
  },
]

const resources = [
  {
    thumb: 'SP-05378_caregiverbro_thumbnail.png',
    title: 'Caregiver Brochure',
    copy:
      'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/bb39c5e5-b03c-40fb-b52b-3110919e08ff/bb39c5e5-b03c-40fb-b52b-3110919e08ff_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Caregiver Brochure',
  },
  {
    thumb: 'SP-05379_gradybear_thumbnail.png',
    title: 'Grady Bear',
    copy: 'Storybook that makes CPP easy for kids to understand',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3daa955d-9baa-4339-815c-d85703531154/3daa955d-9baa-4339-815c-d85703531154_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'Grady Bear Book',
  },
  {
    thumb: 'SP-05697_appreminder_thumbnail.png',
    title: 'Appointment Reminder Card',
    copy: `Helps parents and caregivers keep their child's scheduled appointments`,
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/0bd6b1af-8b41-4b99-9f9b-2e25357a8d35/0bd6b1af-8b41-4b99-9f9b-2e25357a8d35_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Appointment Reminder Card',
  },
  {
    thumb: 'shares-program-leave-behind-thumbnail-img.png',
    title: 'SHARES Program Leave Behind',
    copy: 'Information on copay assistance',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/b2eb57f3-4692-47d9-a184-091143dadcfc/b2eb57f3-4692-47d9-a184-091143dadcfc_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN LA Shares Program',
  },
  {
    thumb: 'SP-05374-caregiver-spanish.webp',
    title: 'Spanish: Caregiver Brochure',
    copy: 'Important information on CPP and how SUPPRELIN<sup>&reg;</sup> LA can help',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/70327155-c50b-4495-8ab4-f7a62b0c9c71/70327155-c50b-4495-8ab4-f7a62b0c9c71_source__v.pdf',
    pdf: true,
    alt: 'SUPPRELIN LA Spanish Caregiver Brochure',
  },
  {
    thumb: 'SP-05379_hgfound_thumbnail.png',
    title: 'HGFound.org',
    copy:
      'The Human Growth Foundation leads the way in providing research, education, support, and advocacy in areas of growth or growth-hormone disorders',
    href: 'https://www.hgfound.org/',
    pdf: false,
    external: true,
    alt: 'hgfound.org',
  },
  {
    thumb: 'SP-05379_magicfound_thumbnail.png',
    title: 'MagicFoundation.org',
    copy:
      'The MAGIC Foundation<sup>&reg;</sup> is the world’s largest organization for children and adults with growth-related disorders',
    href: 'https://www.magicfoundation.org/',
    pdf: false,
    external: true,
    alt: 'magicfoundation.org',
  },
  {
    thumb: 'post-procedure-care-tool-pdf-thumbnail.png',
    title: 'SUPPRELIN<sup>®</sup> LA Post-&#x2060;Procedure Care Tool',
    copy:
      'A tool for your patient’s caregiver after the SUPPRELIN<sup>®</sup> LA implant has been inserted',
    href: 'https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/553bce0b-c8a5-4462-9d1a-b66995aa7a7b/553bce0b-c8a5-4462-9d1a-b66995aa7a7b_source__v.pdf',
    pdf: true,
    external: false,
    alt: 'SUPPRELIN® LA Post-procedure Care Tool',
  },
]

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Important forms and resources for your practice</h1>
      </Col>
    </Row>
    <Collection collection={hcpResources} />
    <Row>
      <Col xs={12}>
        <h1>Caregiver resources</h1>
      </Col>
    </Row>
    <Collection
      collection={resources}
      pageName="hcp downloads office support"
    />
    <Row>
      <Col xs={12}>
        <p className="study-copy">All trademarks are the property of their respective owners.</p>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage;
